<template>
  <div class="list-user admin_container_default">
    <div class="list-user-table">
      <div class="mb-2 row p-0 admin_header">
        <div class="d-flex p-0 admin_title">
          <div class="text-truncate-mess header-font font-weight-bold">
            ニュースカテゴリ管理
          </div>
        </div>
        <div class="row-input admin_center">
          <CInput
            class="form-input m-0 p-0"
            style="width: 100%"
            placeholder="検索"
            v-model="keywords"
            v-on:keyup="getListCategoryNews(1)"
          />
        </div>
        <div class="tex-left text-md-right text-sm-right admin_right">
          <button
            v-on:click="gotoDashboard()"
            class="float-right button-back-dashboard"
          >
            戻る
          </button>
          <button
            v-on:click="createCategoryNew()"
            class="float-right button-create-user"
          >
            カテゴリ作成
          </button>
        </div>
      </div>
      <Tables
        :items="listCategoryNew.data"
        :fields="fields"
        :itemsPerPageSelect="{}"
        class="cpx-15"
      >
        <template v-slot:id="{ index }">
          <td>
            {{
              (listCategoryNew.current_page - 1) * listCategoryNew.per_page +
              index +
              1
            }}
          </td>
        </template>
        <template v-slot:action="{ item, index }">
          <td class="py-2 pl-2 pr-1 d-flex">
            <div class="w-5">
              <router-link
                :to="{
                  name: $route.params.shopId
                    ? 'edit category new'
                    : 'edit category new domain',
                  params: { shopId: $route.params.shopId, id: item.id },
                  query: {
                    NO:
                      (listCategoryNew.current_page - 1) *
                        listCategoryNew.per_page +
                      index +
                      1,
                  },
                }"
                class="d-flex flex-column align-items-center"
              >
                <CButton
                  class="mx-1 btn-other"
                  style="width: 67px"
                  square
                  size="sm"
                  v-b-modal.modal-comfirm
                >
                  編集
                </CButton>
              </router-link>
            </div>
            <div>
              <CButton
                class="mx-1 btn-del"
                style="width: 67px"
                square
                v-on:click="deleteCategoryNews(item.id)"
                size="sm"
              >
                削除
              </CButton>
            </div>
          </td>
        </template>
      </Tables>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listCategoryNew.total"
      >
        <p class="font-weight-bold">
          {{ listCategoryNew.total }}件中{{
            listCategoryNew.data.length
          }}件を表示
        </p>
      </div>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listCategoryNew.last_page"
      >
        <CPagination
          v-if="listCategoryNew.total"
          :activePage.sync="page"
          :pages="listCategoryNew.last_page"
          align="center"
        />
        <div class="custom-selectLimitPage">
          <b-form-select
            v-model="limit"
            tag-placeholder="Add this as new category"
            :options="listLimitRecordPage"
            @change="changeLimitPage()"
          ></b-form-select>
        </div>
      </div>
    </div>
    <div>
      <b-modal id="delete-modal-category-new" hide-header hide-footer>
        <div class="d-block text-center">
          <h5>
            削除後、ご利用頂けなくなります。ご注意の上、削除をお願い致します。（復元不可となります。ご留意くださいませ）
          </h5>
        </div>
        <div class="d-flex justify-content-center">
          <b-button
            class="mx-2 mt-3 btn btn-danger"
            block
            v-on:click.prevent="
              deleteCategoryNew({ id: infoCategoryNew.id, shop_id: shop_id })
            "
            >削除</b-button
          >
          <b-button
            class="mx-2 mt-3 btn btn-secondary"
            block
            @click="$bvModal.hide('delete-modal-category-new')"
          >
            キャンセル
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Tables from "../../components/common/Tables";
import { tableFields } from "../../utils/table-fields.js";
import { Constants } from "../../utils/constants";
import { mapActions, mapGetters } from "vuex";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import $ from "jquery";
import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "@fortawesome/fontawesome-free/css/regular.css";
import "@fortawesome/fontawesome-free/css/solid.css";
$.extend(true, $.fn.datetimepicker.defaults, {
  icons: {
    time: "far fa-clock",
    date: "far fa-calendar",
    up: "fas fa-arrow-up",
    down: "fas fa-arrow-down",
    previous: "fas fa-chevron-left",
    next: "fas fa-chevron-right",
    today: "fas fa-calendar-check",
    clear: "far fa-trash-alt",
    close: "far fa-times-circle",
  },
});
export default {
  name: "ListNew",
  data() {
    return {
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      infoCategoryNew: {
        id: "",
      },
      fields: tableFields.CATEGORY_NEW,
      listLimitRecordPage: [
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 200, text: "200" },
      ],
      limit: 50,
      page: 1,
      optionsDate: {
        format: "YYYY-MM-DD HH:mm",
        useCurrent: false,
        showClear: false,
        showClose: false,
        locale: "ja",
      },
      optionCategory: [],
    };
  },
  components: {
    Tables,
  },
  created() {
    this.getListCategoryNews(1);
  },
  computed: {
    ...mapGetters(["message", "success", "error", "listCategoryNew"]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
        this.$bvModal.hide("delete-modal-category-new");
        this.getListCategoryNews(this.page);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    listCategoryNew() {
      this.page = this.listCategoryNew.current_page;
    },
    page() {
      this.listCategoryNews(this.page);
    },
  },
  methods: {
    ...mapActions({
      deleteCategoryNew: "deleteCategoryNew",
      getListCategoryNew: "getListCategoryNew",
    }),
    createNew() {
      this.$router.push({
        name: this.$route.params.shopId
          ? "create new admin"
          : "create new admin domain",
        query: { total: this.listNew.total + 1 },
      });
    },
    getListCategoryNews(page) {
      if (page === undefined) {
        page = this.page;
      }
      const formData = {
        screen: "admin",
        page: page,
        shop_id: this.shop_id,
        limit: this.limit,
        keywords: this.keywords,
      };
      this.getListCategoryNew(formData);
    },
    changeLimitPage() {
      this.getListCategoryNews(1);
    },
    gotoDashboard() {
      if (this.$route.params.shopId) {
        this.$router.push({ name: "Dashboard Admin" });
      } else {
        this.$router.push({ name: "Dashboard Admin domain" });
      }
    },
    resetFieldSearch() {
      const formData = {
        screen: "admin",
        page: 1,
        shop_id: this.shop_id,
        limit: this.limit,
      };
      this.getListCategoryNew(formData);
    },
    deleteCategoryNews(id) {
      this.infoCategoryNew.id = id;
      this.$bvModal.show("delete-modal-category-new");
    },
    createCategoryNew() {
      this.$router.push({
        name: this.$route.params.shopId
          ? "create category new"
          : "create category new domain",
        query: {
          total: this.listCategoryNew.total
            ? this.listCategoryNew.total + 1
            : 1,
        },
      });
    },
  },
};
</script>
